import * as lt from 'leaflet';
import type { PointLike } from '@contentya/maps-point';
import { pointTupleImpl } from '@contentya/maps-point';

/**
 * Converts {@link PointLike} to leaflet's
 * {@link https://leafletjs.com/reference.html#point | Point}.
 */
export function ltPoint(point: PointLike): lt.Point {
    if (point instanceof lt.Point) {
        return point;
    }
    return new lt.Point(...pointTupleImpl('ltPoint()', point));
}

export function compatPointLike(point: lt.PointExpression): PointLike {
    return point;
}
