import * as lt from 'leaflet';
import type { LngLatLike } from '@contentya/lnglat-coords';
import { isLatLngTuple, latLngTupleImpl } from '@contentya/lnglat-coords';

/**
 * Converts {@link LngLatLike} to leaflet's
 * {@link https://leafletjs.com/reference.html#latlng | LatLng}.
 */
export function ltLatLng(coords: LngLatLike): lt.LatLng {
    if (coords instanceof lt.LatLng) {
        return coords;
    }
    return new lt.LatLng(...latLngTupleImpl('ltLatLng()', coords));
}

export function ltLatLngDefault(
    coords?: LngLatLike | null | undefined,
    defaultLatLng: lt.LatLngExpression = { lat: 0, lng: 0 }
): lt.LatLngExpression {
    if (coords == null) {
        return defaultLatLng;
    }
    return ltLatLng(coords);
}

export function compatLngLatLike(coords: lt.LatLngExpression): LngLatLike {
    if (isLatLngTuple(coords)) {
        return [coords[1], coords[0]];
    }

    return coords;
}
