import * as lt from 'leaflet';
import type { LngLatBoundsLike } from '@contentya/lnglat-bounds';
import { lngLatBoundsPairImpl } from '@contentya/lnglat-bounds';
import { isLatLngTuple } from '@contentya/lnglat-coords';
import { ltLatLng } from './coords';

export function ltLatLngBounds(bounds: LngLatBoundsLike): lt.LatLngBounds;
export function ltLatLngBounds(bounds: unknown): lt.LatLngBounds {
    if (bounds instanceof lt.LatLngBounds) {
        return bounds;
    }

    const [sw, ne] = lngLatBoundsPairImpl('ltLatLngBounds()', bounds);

    return new lt.LatLngBounds(ltLatLng(sw), ltLatLng(ne));
}

export function compatLngLatBoundsLike(bounds: lt.LatLngBoundsExpression): LngLatBoundsLike;
export function compatLngLatBoundsLike(bounds: unknown): LngLatBoundsLike {
    function flip<T>([x, y]: [T, T]): [T, T] {
        return [y, x];
    }

    if (bounds instanceof lt.LatLngBounds) {
        return bounds;
    }

    if (Array.isArray(bounds)) {
        if (bounds.length >= 2 && isLatLngTuple(bounds[0]) && isLatLngTuple(bounds[1])) {
            return [flip(bounds[0]), flip(bounds[1])];
        }
    }

    throw new TypeError('invalid argument provided to compatLngLatBoundsLike()');
}
