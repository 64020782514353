import * as lt from 'leaflet';
import type * as maps from '@contentya/maps-interface';
import type { LngLatLike, LngLatLiteral } from '@contentya/lnglat-coords';
import { ltLatLng, ltLatLngDefault } from './coords';
import { ltMapOptions } from './mapoptions';
import { ltPanOptions, ltFlyToOptions } from './cameraoptions';

interface FuncWithNullifiedArg<T, R> {
    (_: T | unknown | null): R;
}

export class Map implements maps.MapAdapter<lt.Map> {
    private readonly _map: lt.Map;

    constructor(map: lt.Map) {
        this._map = map;
    }

    adaptee(): lt.Map {
        return this._map;
    }

    flyTo(options: maps.FlyToOptions): this {
        const center = ltLatLngDefault(options?.center, this.getCenter());
        this._map.flyTo(center, options?.zoom, ltFlyToOptions(options));
        return this;
    }

    getCenter(): LngLatLiteral {
        return this._map.getCenter();
    }

    getContainer(): HTMLElement {
        return this._map.getContainer();
    }

    getHeading(): number | undefined {
        // TODO: looks like leaflet does not support heading, but it needs to be investigated
        return undefined;
    }

    getMaxZoom(): number {
        return this._map.getMaxZoom();
    }

    getMinZoom(): number {
        return this._map.getMinZoom();
    }

    getTilt(): number | undefined {
        // TODO: looks like leaflet does not support tilt, but it needs to be investigated
        return undefined;
    }

    getZoom(): number {
        return this._map.getZoom();
    }

    panTo(coords: LngLatLike, options?: maps.PanOptions): this {
        if (typeof options === 'undefined') {
            this._map.panTo(ltLatLng(coords));
        } else {
            this._map.panTo(ltLatLng(coords), ltPanOptions(options));
        }
        return this;
    }

    setCenter(center: LngLatLike): this {
        this._map.panTo(ltLatLng(center));
        return this;
    }

    setHeading(): this {
        // TODO: looks like leaflet does not support heading, but it needs to be investigated
        return this;
    }

    setMaxZoom(maxZoom?: number | null | undefined): this {
        (this._map.setMaxZoom as unknown as FuncWithNullifiedArg<number, this>)(maxZoom);
        return this;
    }

    setMinZoom(minZoom?: number | null | undefined): this {
        (this._map.setMinZoom as unknown as FuncWithNullifiedArg<number, this>)(minZoom);
        return this;
    }

    setTilt(): this {
        // TODO: looks like leaflet does not support tilt, but it needs to be investigated
        return this;
    }

    setZoom(zoom: number): this {
        this._map.setZoom(zoom);
        return this;
    }
}

export function map(container: string | HTMLElement, options?: maps.MapOptions) {
    if (options === undefined) {
        return new Map(lt.map(container));
    }
    return new Map(lt.map(container, ltMapOptions(options)));
}
