import type * as lt from 'leaflet';
import type * as maps from '@contentya/maps-interface';

type LtPanOptions<T extends maps.PanOptions> = Omit<T, 'duration'> & lt.PanOptions;

export function ltPanOptions<T extends maps.PanOptions>(options: T): LtPanOptions<T> {
    const { duration, ...rest } = options;
    const adjusted: LtPanOptions<T> = rest;

    if (duration !== undefined) {
        adjusted.duration = duration / 1000.0;
    }

    return adjusted;
}

type LtFlyToOptions<T extends maps.FlyToOptions> = LtPanOptions<T>;

export function ltFlyToOptions<T extends maps.FlyToOptions>(options: T): LtFlyToOptions<T> {
    return ltPanOptions(options);
}
