import * as lt from 'leaflet';
import type * as maps from '@contentya/maps-interface';
import type { LngLatLike } from '@contentya/lnglat-coords';
import { Map } from './map';
import { ltLatLngDefault } from './coords';
import { ltMarkerOptions } from './markeroptions';

export class Marker implements maps.MarkerAdapter<lt.Marker> {
    _marker: lt.Marker;

    constructor(marker: lt.Marker) {
        this._marker = marker;
    }

    adaptee(): lt.Marker {
        return this._marker;
    }

    setPosition(position: LngLatLike | null): this {
        this._marker.setLatLng(ltLatLngDefault(position));
        return this;
    }

    getPosition(): lt.LatLng {
        return this._marker.getLatLng();
    }

    addToMap(map: maps.Map): this {
        if (map instanceof Map) {
            this._marker.addTo(map.adaptee());
        }
        return this;
    }

    removeFromMap(): this {
        this._marker.remove();
        return this;
    }
}

export function marker(options?: maps.MarkerOptions): Marker {
    if (typeof options === 'undefined') {
        return new Marker(lt.marker(ltLatLngDefault()));
    }

    const { map, position } = options;
    const ltOpts = ltMarkerOptions(options);
    const ltPos = ltLatLngDefault(position);
    const marker = new Marker(lt.marker(ltPos, ltOpts));

    if (map != null) {
        marker.addToMap(map);
    }

    return marker;
}
