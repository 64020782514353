import * as lt from 'leaflet';
import type * as maps from '@contentya/maps-interface';
import { ltLatLng } from './coords';
import { ltLatLngBounds } from './bounds';

export function ltDefaultLayers(): lt.Layer[] {
    return [
        lt.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }),
    ];
}

type LtMapOptions<T extends maps.MapOptions> = Omit<
    T,
    'center' | /*'layers' |*/ 'maxBounds' | 'maxZoom' | 'minZoom' | 'zoom' | 'zoomControl'
> &
    lt.MapOptions;

export function ltMapOptions<T extends maps.MapOptions>(options: T): LtMapOptions<T> {
    const { center, /*layers, */ maxBounds, maxZoom, minZoom, zoom, zoomControl, ...rest } = options;

    const adjusted: LtMapOptions<T> = rest;

    if (center != null) {
        adjusted.center = ltLatLng(center);
    }

    if (typeof maxBounds !== 'undefined') {
        adjusted.maxBounds = ltLatLngBounds(maxBounds);
    }

    // adjusted.layers = layers === undefined ? ltDefaultLayers() : layers;
    adjusted.layers = ltDefaultLayers();

    if (maxZoom != null) {
        adjusted.maxZoom = maxZoom;
    }

    if (minZoom != null) {
        adjusted.minZoom = minZoom;
    }

    if (zoom != null) {
        adjusted.zoom = zoom;
    }

    if (zoomControl != null) {
        adjusted.zoomControl = zoomControl;
    }

    return adjusted;
}
