import type * as lt from 'leaflet';
import type * as maps from '@contentya/maps-interface';
import { ltPoint } from './point';

type LtMarkerOptions<T extends maps.MarkerOptions> = Omit<T, 'autoPanPadding' | 'icon' | 'label' | 'shape'> &
    lt.MarkerOptions;

export function ltMarkerOptions<T extends maps.MarkerOptions>(options: T): LtMarkerOptions<T> {
    const { autoPanPadding, icon, label, shape, ...rest } = options;
    const adjusted: LtMarkerOptions<T> = rest;

    if (autoPanPadding !== undefined) {
        adjusted.autoPanPadding = ltPoint(autoPanPadding);
    }

    console.assert(icon === undefined, 'ltMarkerOptions(): MarkerOptions.icon is not implemented yet');
    console.assert(label === undefined, 'ltMarkerOptions(): MarkerOptions.label is not implemented yet');
    console.assert(shape === undefined, 'ltMarkerOptions(): MarkerOptions.shape is not implemented yet');

    return adjusted;
}
